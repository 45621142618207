<template>
    <div class="sld_chat_left">
        <div class="top">
            <div class="info">
                <img :src="storeInfo.data.storeLogoUrl" alt="">
                <div class="text">
                    <p>
                        <span>{{storeInfo.data.vendorName}}</span>
                        <img src="@/assets/service/service_logo.png" alt="">
                        <!-- <i class="iconfont"></i> -->
                    </p>
                    <p>(在线中)</p>
                </div>
            </div>
        </div>
        <div class="list_wrap">
            <!--            <div class="tab">-->
            <!--                <span @click="changeTab(1)" :class="{tabChosen:1==tabIndex}"><i>进行中(1111)</i></span>-->
            <!--                <span @click="changeTab(2)" :class="{tabChosen:2==tabIndex}"><i>进行中(1111)</i></span>-->
            <!--            </div>-->
            <div class="list_con" :style="'height:'+(clientHeight-152)+'px'">
                <el-scrollbar id="leftScroll">
                    <div v-if="tabIndex==1&&chatList.list.length" v-infinite-scroll="load">
                        <div class="list_item"
                            :style="{'background-color':item.memberId == curMemberId?'#F5F5F5':'#fff'}"
                            v-for="(item,index) in chatList.list" :key="index" id="processing"
                            @click="switchMember(item.memberId,item.memberName)">
                            <img :src="item.memberAvatar">
                            <div class="list_text">
                                <p class="list_item_title">{{item.memberName}}</p>
                                <p class="msg_new">{{item.showContent}}</p>
                            </div>
                            <div class="delete_icon">
                                <i v-if="item.receiveMsgNumber"
                                    class="msg_icon">{{item.receiveMsgNumber>99?'99+':item.receiveMsgNumber}}</i>
                                <div class="del_icon">
                                    <el-popconfirm title="确定结束与该会员的聊天吗？" @confirm="closeChatMember(item.memberId)"
                                        confirmButtonText='确定' cancelButtonText='取消'>
                                        <template #reference>
                                            <img src="@/assets/service/delete.png" alt="">
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                        </div>
                        <loadingState v-if="loadState == 'first_loading'||chatList.list.length > 0"
                            :state='loadState' />
                    </div>
                    <div class="empty_data_left" v-if="!chatList.list.length>0">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>暂无数据～</p>
                    </div>
                </el-scrollbar>
                <!-- <div v-if="tabIndex==2">
                    <div class="list_item" v-for="(item,index) in [1,2,3,4,5]" :key="index" id="queuing">
                        <img src="@/assets/service/avatar.png" alt="">
                        <div class="list_text">
                            <p class="list_item_title">aawdawd{{item}}</p>
                            <p class="msg_new">awdawddadawdawdawdawdawdawdawdawdawd</p>
                        </div>
                        <div class="delete_icon">
                            <img src="@/assets/service/delete.png" alt="">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
    import { useStore } from "vuex";
    import loadingState from '@/components/loadingState'
    export default {
        name: 'chatLeftList',
        components: {
            loadingState
        },
        props: ['connectBaseData'],
        beforeCreate() {
            this.sockets.subscribe('contact_change', (e) => {
                if (this.chatList.list.length == 0) {
                    this.chatList.list.push(e);
                    this.$socket.emit("vendor_change_room", { memberId: e.memberId, ...this.connectData }, () => {
                        this.emitParentData({ memberId: e.memberId, memberName: e.memberName });
                    });
                } else {
                    let cur_index = this.chatList.list.findIndex(item => item.memberId == e.memberId);
                    if (cur_index == -1) {
                        this.chatList.list.unshift(e);
                    } else {
                        this.chatList.list[cur_index] = e;
                    }
                }
                this.formatMsgContent();
            });
            this.sockets.subscribe('unread_num_change', (e) => {
                let tmp_data = this.chatList.list.filter(item => item.memberId == e.memberId)
                if (tmp_data.length == 1) {
                    tmp_data[0].receiveMsgNumber = e.unreadNum;
                }
            });
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const connectData = reactive(props.connectBaseData);
            const store = useStore();
            const tabIndex = ref(1);
            const storeInfo = reactive({ data: store.state.storeInfo });
            const pageSize = 20;
            const current = ref(1);
            const curMemberId = ref(0);//当前聊天会员的id
            const minMsgId = ref('');//当前消息的最小id
            const chatList = reactive({ list: [] });//最近联系人列表
            const loadState = ref('')
            const changeTab = (index) => {
                tabIndex.value = index
            }
            const isScroll = ref(false)
            const hasMore = ref(true)
            const clientHeight = ref(0)
            //获取最近联系人
            const getChatList = () => {
                const params = {};
                params.pageSize = pageSize;
                params.current = current.value;
                if (minMsgId.value) {
                    params.msgId = minMsgId.value;
                }
                proxy.$get('v3/helpdesk/seller/chat/list', params).then(res => {
                    if (res.state == 200) {
                        if (minMsgId.value) {
                            chatList.list = chatList.list.concat(res.data);
                        } else {
                            chatList.list = res.data
                        }
                        formatMsgContent()
                        if (chatList.list.length > 0) {
                            minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                            if (proxy.$socket.connected) {
                                curMemberId.value = chatList.list[0].memberId;
                                proxy.$socket.emit("vendor_change_room", { memberId: curMemberId.value, ...connectData }, () => {
                                    if (isScroll.value == false) {
                                        emit('switchMember', { memberId: curMemberId.value, memberName: chatList.list[0].memberName });
                                    }
                                });
                            }
                            // if (isScroll.value == false) {
                            //     emit('curChatMemInfo', chatList.list[0])
                            // }

                        } else {
                            curMemberId.value = 0;
                            emit('curChatMemInfo', {})
                        }
                        hasMore.value = res.data.length < 10 ? false : true;
                        if (hasMore.value) {
                            loadState.value = 'allow_loading_more';
                        } else {
                            loadState.value = 'no_more_data';
                        }
                    }
                })
            }
            //格式化聊天内容，方便列表展示
            const formatMsgContent = () => {
                let reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g

                let reg4 = /(<\/?div.*?>)|(<\/?br.*?>)|(<\/?span.*?>)/g;
                if (chatList.list.length > 0) {
                    chatList.list.map(item => {
                        if (typeof item.msgContent == 'string') {
                            item.msgContent = JSON.parse(item.msgContent)
                        }
                        //1.text(文本) 2.img(图片) 3.goods(商品) 4.order(订单)用户
                        if (item.msgType == 1) {
                            if (reg.test(item.msgContent.content)) {
                                item.showContent = item.msgContent.content.replace(reg, '[表情]')
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                            if (reg4.test(item.msgContent.content)) {
                                item.showContent = item.msgContent.content.replace(reg, '')
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                        } else if (item.msgType == 2) {
                            item.showContent = '[图片]';
                        } else if (item.msgType == 3) {
                            item.showContent = '[商品]';
                        } else if (item.msgType == 4) {
                            item.showContent = '[订单]';
                        }
                    })
                }
            }

            //切换会员事件
            const switchMember = (memberId, memberName) => {
                let oldMemberId = curMemberId.value;
                curMemberId.value = memberId;
                proxy.$socket.emit("vendor_change_room", { memberId, oldMemberId, ...connectData });
                emit('switchMember', { memberId, memberName });
            }

            //关闭与会员的聊天事件
            const closeChatMember = (memberId) => {
                proxy.$socket.emit("vendor_remove_contact", { memberId, ...connectData });
                chatList.list = chatList.list.filter(item => item.memberId != memberId);
                if (curMemberId.value == memberId) {
                    if (chatList.list.length > 0) {
                        //该会员与当前聊天的会员是同一个，需要先移除会员再切换
                        switchMember(chatList.list[0].memberId, chatList.list[0].memberName);
                    } else {
                        emit('curChatMemInfo', {})
                    }
                }
            }


            // 向下滚动至底部加载数据
            const load = () => {
                if (hasMore.value) {
                    isScroll.value = true
                    getChatList()
                }
            }

            onMounted(() => {
                setTimeout(() => {
                    storeInfo.data = JSON.parse(localStorage.getItem('storeInfo'))
                }, 1000)
                clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
                getChatList();
            })

            const emitParentData = (data) => {
                emit('switchMember', { memberId: data.memberId, memberName: data.memberName });
            }

            return {
                tabIndex,
                changeTab,
                chatList,
                storeInfo,
                formatMsgContent,
                curMemberId,
                switchMember,
                closeChatMember,
                load,
                loadState,
                emitParentData,
                connectData,
                clientHeight
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sld_chat_left {
        width: 300px;
        /* border: 1px solid #9999; */
        flex-shrink: 0;

        .top {
            .info {
                display: flex;
                margin-top: 11px;

                &>img {
                    width: 44px;
                    height: 44px;
                    margin-left: 20px;
                }

                .text {
                    margin-left: 12px;

                    p {
                        height: 23px;
                        display: flex;
                        align-items: center;

                        span {
                            display: inline-block;
                            height: 15px;
                            line-height: 15px;
                            margin-right: 10px;
                        }

                        &:nth-child(2) {

                            color: #51A4FD;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .list_wrap {
            margin-top: 10px;
            width: 100%;

            .tab {
                height: 46px;

                span {
                    display: inline-block;
                    text-align: center;
                    height: 52px;
                    padding: 17px 35px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border-bottom: 1px solid #f7f7f7;
                    cursor: pointer;

                    i {
                        font-style: normal;
                    }
                }

                .tabChosen {
                    border-bottom: 2px solid #0871FF;

                    i {
                        width: 95px;
                        height: 17px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #0B72F2;
                    }
                }
            }

            .list_con {
                margin-top: 10px;

                .list_item {
                    padding: 15px 17px;
                    width: 97%;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #f7f7f7;
                    position: relative;

                    &>img {
                        width: 40px;
                        height: 40px;
                    }


                    &:hover {
                        background-color: #F5F5F5;

                        .delete_icon {
                            .del_icon {

                                display: block;
                            }

                        }
                    }

                    .list_text {
                        margin-left: 10px;

                        .list_item_title {
                            height: 15px;
                            line-height: 15px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }

                        .msg_new {
                            width: 175px;
                            margin-top: 9px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .delete_icon {
                        position: absolute;
                        right: 17px;
                        display: flex;

                        i {
                            font-style: normal;
                            margin-right: 6px;
                            display: inline-block;
                            padding: 2px 5px;
                            background-color: #e2231a;
                            color: #fff;
                            font-size: 12px;
                            border-radius: 9px;
                            transform: scale(0.9)
                        }

                        .del_icon {
                            display: none;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
    }

    .empty_data_left {
        height: 300px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
        }

        p {
            margin-top: 15px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #D5D5D5;
        }
    }
</style>